.wrapped {
  width: 100%;
  height: 100%;
  transform: translate(0, 0) !important;
}

svg {
  overflow: scroll !important;
}

svg g:hover text {
  fill-opacity: 1;
}

svg g text {
  font-size: 1px;
  fill: rgba(159, 3, 3, 0.3);
}

svg path {
  fill: none;
  stroke: yellowgreen;
  stroke-width: 0.02em;
  stroke-opacity: 0.6;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 0.5;
}

.hexagon-head-svg polygon {
  stroke: #938200;
  stroke-width: 1px;
  stroke-dasharray: 3;
  stroke-opacity: 0.5;
}

.hexagon {
  font-size: 1pt;
}

.content-grid {
  background: #000;
  background-image: url("./icons/galaxy.jpg");
}

/*BACKGROUND*/
#gradient {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: 2;
}

.gis-info {
  position: fixed;
  bottom: 80px;
  padding: 12px;
  color: #fff;
  background: rgba(58, 164, 71, 0.6);
}

.self-info {
  position: fixed;
  bottom: 0;
  left: 10px;
  padding: 12px;
  color: #fff;
  background: rgba(58, 164, 71, 0.6);
}

.target-info {
  position: fixed;
  top: 100px;
  left: 10px;
  padding: 12px;
  color: #fff;
  background: rgba(58, 164, 71, 0.6);
}

@media (min-width: 576px) {
  .counter-info {
    width: 100vw;
  }

  .cap-info {
    width: 50% !important;
  }
}
@media (min-width: 768px) {
  .counter-info {
    width: 100vw;
  }

  .cap-info {
    width: 50% !important;
  }

  .cap-info div {
    max-width: 200px !important;
  }
}
@media (min-width: 992px) {
  .counter-info {
    width: 80vw;
    transform: translateX(10vw);
  }

  .avatar-l {
    width: 100px !important;
  }

  .cap-info {
    width: 50% !important;
  }

  .avatar-l .hex-head {
    min-width: 100px;
    min-height: 100px;
  }

  .cap-info div {
    max-width: 200px !important;
  }
}
@media (min-width: 1200px) {
  .counter-info {
    width: 60vw;
    transform: translateX(20vw);
  }

  .avatar-l .hex-head {
    min-width: 100px;
    min-height: 100px;
  }

  .cap-info div {
    max-width: 200px !important;
  }
}
@media (min-width: 1500px) {
  .counter-info {
    width: 60vw;
    transform: translateX(20vw);
  }

  .avatar-l .hex-head {
    min-width: 100px;
    min-height: 100px;
  }

  .cap-info div {
    max-width: 200px !important;
  }
}
.counter-info {
  position: fixed;
  min-height: 100px;
  overflow: hidden;
  height: auto;
  display: flex;
  z-index: 10;
  opacity: 0.9;
}

.owner-info {
  background: linear-gradient(#011a60, #475067);
  flex: 1;
  position: relative;
  text-align: left;
  display: flex;
  padding: 6px;
}

.owner-info .cap-info {
  float: left;
}

.avatar-l {
  float: left;
  width: 40%;
}

.avatar-l .hex-head {
  position: relative;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 1px 1px #dddddd;
  margin-bottom: 2px;
}

.avatar-l .attr {
  min-height: 23px;
}

.cap-info {
  font-size: 14px;
  font-weight: 800;
  color: #f1d204;
  text-shadow: 2px 2px 2px #000;
}

.cap-info div {
  border: 1px solid #96bf9b;
  min-height: 25px;
  margin: 0px 0px 3px 6px;
  border-radius: 5px;
  position: relative;
  min-width: 100px;
  max-width: 120px;
  padding-top: 2px;
}

.cap-info div img {
  position: absolute;
  top: 2px;
}

.cap-info div span {
  padding-left: 25px;
}

.cap-info div span img {
  position: absolute;
  right: 5px;
}

.enemy-info {
  flex: 1;
  background: linear-gradient(#560817, #674750);
  display: flex;
  padding: 6px;
}

.enemy-info .avatar-l {
  float: right;
  right: 0;
  position: relative;
}

.avatar-l .attr {
  width: 100%;
  display: flex;
  color: #fff;
  font-size: 12px;
  font-weight: 800;
  text-shadow: 3px 2px 2px #000;
  text-align: center;
}

.avatar-l .attr div {
  flex: 1;
}

.enemy-info .cap-info {
  float: right;
}

.life {
  height: 20px !important;
  min-height: 15px !important;
  border: 1px solid #ddd !important;
  border-radius: 5px !important;
  padding: 0 0 2px 0 !important;
}

.life-progress {
  --background: #754141;
  --progress-background: linear-gradient(#f6041c 30%, #750202);
  height: 18px;
  border-radius: 5px !important;
}

.life-value {
  position: absolute !important;
  right: -20px !important;
  top: -4px !important;
  border: none !important;
  font-size: 10px !important;
  text-shadow: none !important;
  color: #f7f7f7 !important;
  z-index: 100;
  display: flex;
  align-items: center;
}

.eye {
  position: absolute;
  right: 0;
  bottom: 0;
}

.hex-head .coo {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
  font-size: 10px;
  text-align: center;
  font-weight: 800;
  background-color: rgba(0, 0, 0, 0.6);
  left: 0;
}

.bg-select {
  flood-color: #f0f;
  animation: bgShadow 3s linear infinite alternate;
}

@keyframes bgShadow {
  from {
    flood-color: #0ff;
  }
  to {
    flood-color: #f0f;
  }
}
.btn-div {
  position: absolute;
  z-index: 1;
  color: #fff;
}

.counter-list-modal {
  --width: 90%;
  --height: 80%;
  --border-radius: 15px;
  --background:#fff;
  overflow-y: scroll;
  --max-width: 512px;
}

.coo-list-modal {
  --height: 60vh;
  --width: 80vw;
  --max-width: 512px;
  --border-radius: 15px;
}

.counter-list-half {
  max-height: 30vh;
  overflow-y: scroll;
}

.counter-list-md {
  max-height: 90%;
  overflow-y: scroll;
}

.op-box {
  position: absolute;
  top: 20%;
  right: 0;
  padding: 6px;
  z-index: 10;
  border-radius: 15px;
  background: rgba(26, 41, 82, 0.6);
  color: #fff;
  font-size: 10px;
}

.settle-modal {
  --max-width: 900px !important;
}

.attribution-input {
  width: 60px;
  border: 1px solid;
  border-radius: 5px;
  margin: 2px;
  height: 30px;
  text-align: center;
  font-weight: 600;
}

.counter-list {
  max-height: 30vh;
  overflow-y: scroll;
  border: 1px solid #ddd;
  padding: 6px;
  margin: 6px 12px 0;
  border-radius: 15px;
}

.counter-list-select {
  max-height: 60vh;
  overflow-y: scroll;
  border: 1px solid #ddd;
  padding: 6px;
  margin: 6px 12px 0;
  border-radius: 15px;
}

.operator-btn {
  bottom: 0;
  border: none !important;
  overflow: hidden;
}

.op-time .countdown {
  color: #fff !important;
}

.settle-box {
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 12px;
  margin-bottom: 12px;
}

.settle-box-2 {
  border: 1px solid #ddd;
  border-radius: 15px;
  margin-bottom: 12px;
}

.settle-box .bt {
  border-bottom: 1px dashed #dddddd;
  text-align: center;
}

.counter-id {
  position: absolute;
  left: 0;
  font-size: 12px;
  top: 0;
  color: #f1d204;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 50%;
  padding: 2px;
  font-weight: 600;
}

.wthdown {
  font-size: 12px;
}

.op-countdown {
  border: unset !important;
  margin-left: 24px !important;
  color: red;
}

.period-countdown {
  color: #dddddd;
  font-size: 12px;
}

.period-countdown2 {
  color: #dddddd;
  font-size: 14px;
}

.p-cd-r {
  position: absolute;
  right: 24px;
}

.op-countdown-2 {
  color: red;
  text-align: left;
  font-size: 14px;
  transform: translateY(1px);
}

.pd-in {
  padding-inline-end: unset !important;
  padding-inline-start: unset !important;
}

.cap-info .ctime {
  border: unset !important;
}

.en-box {
  min-width: 100px;
  min-height: 100px;
  border: 1px solid #1a2952;
  background: rgba(205, 92, 92, 0.5);
}

.epoch-modal {
  --width: 90%;
  --height: 80%;
  --border-radius: 15px;
  --background:#fff;
  overflow-y: scroll;
  --max-width: 600px;
}

.epoch-md {
  max-height: 85vh;
  overflow: scroll;
  padding-bottom: 80px;
}

.epoch-md .close {
  float: right;
  padding: 10px 12px 75px;
}

.btn-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 5px;
  background: #fff;
}

.font-weight-800 {
  font-weight: 800;
}

.modal-header {
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  padding: 12px;
}

.modal-select-account {
  --height: 40%;
  --width: 90vw;
  --border-radius: 15px;
}

.select-account-btn {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

